<template>
    <div
        class="p-8 bg-white rounded-2xl min-w-[360px] xsm:min-w-[500px] max-h-100vh-7 overflow-y-auto"
        :class="$bem({})"
        ref="self"
    >
        <div v-if="!timerStarted">
            <div
                class="text-h3 tracking-[3px] font-medium text-center uppercase sm:text-h2"
                :class="$bem({ e: 'title' })"
            >
                Перезвоните мне
        </div>
            <p
                class="mt-4 text-body text-shuttle-gray"
                :class="$bem({ e: 'text' })"
            >
                Мы с радостью перезвоним Вам в течение 15 секунд
            </p>
            <ul
                v-if="errors"
                class="mt-4 bg-roman roundex-2xl flex flex-col items-center justify-center"
                :class="$bem({ e: 'errors-list' })"
            >
                <li
                    class="text-body-small text-black"
                    :class="$bem({ e: 'error-item' })"
                    v-for='(error, key) in errors'
                    :key='key'
                >
                    {{ error }}
                </li>
            </ul>
            <form
                class="mt-4"
                :class="$bem({ e: 'form' })"
                @submit.prevent="sumbitForm"
            >
                <input
                    id="phone"
                    class="w-full border rounded-md border-pale-sky-30 py-2 px-3 focus:border-black transition-colors duration-200 outline-none"
                    :class="$bem({ e: 'input' })"
                    type="tel"
                    placeholder="+7"
                    v-model="phone"
                    autocomplete="tel"
                    required
                    v-maska="'+7 ### ###-##-###'"
                    @maska="onMaska"
                >
                <div class="mt-6 w-full flex flex-col">
                    <h2 class="text-lg font-semibold mb-4">Выберите время для звонка</h2>
                    <div class="flex items-center flex-wrap gap-x-6 gap-y-2 w-full">
                        <div class="flex items-center gap-6">
                            <div class="flex items-center cursor-pointer">
                                <input type="radio" :id="`${formId}_now`" value="now" v-model="selectedTime" class="radio-input mr-2">
                                <label :for="`${formId}_now`" class="radio-label">Сейчас</label>
                            </div>
                        </div>
                        <div class="flex items-center gap-x-4 gap-y-2 flex-wrap min-h-[40px] select-input-wrapper">
                            <div class="flex items-center cursor-pointer">
                                <input type="radio" :id="`${formId}_exact`" value="exact" v-model="selectedTime" class="radio-input mr-2">
                                <label :for="`${formId}_exact`" class="radio-label">Точное время</label>
                            </div>
                            <div class="flex items-center gap-x-4" v-show="selectedTime == 'exact'">
                                <select v-model="callHours" class="border p-2 rounded select-input">
                                    <option v-for="hour in 24"
                                        :key="hour"
                                        :value="hour"
                                        :disabled="nowHours > hour"
                                    >
                                        {{ String(hour).length > 1 ? hour : `0${hour}` }}
                                    </option>
                                </select>
                                <select v-model="callMinutes" class="border p-2 rounded select-input">
                                    <option v-for="minute in Array.from({ length: 12 }, (_, i) => i * 5)"
                                        :key="minute"
                                        :value="minute"
                                    >
                                        {{ String(minute).length > 1 ? minute : `0${minute}` }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    class="mt-4 button button--black !block mx-auto the-header-callback-form__button"
                    :class="{'btn-primary': disabled }"
                    type="submit"
                    :disabled="disabled"
                >Жду звонка</button>
                <BasicSwitch v-model="isPolicyChecked" class="switch text-caption text-shuttle-gray mt-4">
                    <span class="relative sm:pb-[2rem] text-left block">
                        Я соглашаюсь с условиями <a class="font-medium fade-hover-effect" :href="links?.license.href ?? '/license/'">Политики конфиденциальности</a>,<br>
                        даю <a class="font-medium fade-hover-effect" href="/personalnye-dannye/" target="_blank">Согласие на обработку персональных данных</a> <br>
                        и получение сообщений.
                        <span v-if="!isPolicyChecked" class="pt-2 sm:pt-0 sm:absolute bottom-0 text-crimson block">Подтвердите согласие на обработку персональных данных</span>
                    </span>
                </BasicSwitch>
            </form>
        </div>
        <div :class="$bem({ e: 'timer' })" v-else>
            <div
                class="flex items-center"
                :class="$bem({ e: 'timer-countdown' })"
            >
                <svg
                    class="mr-2"
                    :class="$bem({ e: 'timer-icon' })"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                        <circle cx="12" cy="12" r="9" stroke="#89899F" stroke-width="1.7"/>
                        <path d="M12 12L12 7" stroke="#89899F" stroke-width="1.7" stroke-linecap="round"/>
                        <path d="M5.70711 4.70711L5 4" stroke="#89899F" stroke-width="1.7" stroke-linecap="round"/>
                        <path d="M17.9999 4.70711L18.707 4" stroke="#89899F" stroke-width="1.7" stroke-linecap="round"/>
                        <path d="M12 12H18" stroke="#89899F" stroke-width="1.7" stroke-linecap="round"/>
                </svg>
                {{millesecondsToTime}}
            </div>
            <div
                class="mt-4 text-body text-shuttle-gray"
                :class="$bem({ e: 'timer-text' })"
            >Спасибо что воспользовались сервисом. Менеджер свяжется с вами в указанное время.</div>
        </div>
    </div>
</template>


<script lang="ts">
import {
    computed,
    defineComponent,
    ref
}                                       from "vue"
import store                            from '~/store/base'
import cartStore                        from "~/store/cart"
import BasicSwitch                      from "~vue/misc/BasicSwitch.vue"
import { yandexDatalayerForm }          from '~/helpers/yandex-datalayer'
import { mindboxPushCustomerForm }      from '~/helpers/mindbox-push'
import { fetchRequest }                 from "~/helpers/fetch"
import { WindowCustom }                 from "~/interfaces/window"
import {
    getHours,
    format,
    setHours,
    setMinutes,
    setSeconds,
    isBefore,
    addDays
}                                       from 'date-fns'
import { commonXHRHeaders }             from "~/helpers/common-xhr-headers"

export default defineComponent({
    name: "TheHeaderCallbackForm",
    components: { BasicSwitch },
    setup() {
        const { links } = store.refs
        const { getDataMindboxForCartUser } = cartStore.actions
        const { getYandexCounterId } = cartStore.computeds

        const selectedTime = ref<string>('now')
        const callHours = ref<string>(String(getHours(new Date())))
        const callMinutes = ref<string>('0')
        const nowHours = ref<number>(getHours(new Date()))

        const self = ref<HTMLElement | null>(null)

        const formId = computed(
            () => {
                const form = self.value
                const parentNode = form?.parentNode as HTMLElement | null

                return parentNode?.id
            }
        )

        return {
            links,
            getDataMindboxForCartUser,
            getYandexCounterId,
            selectedTime,
            callHours,
            callMinutes,
            nowHours,
            self,
            formId,
        }
    },
    data() {
        return {
            errors: [] as string[],
            phone: '+7',
            exactTime: false,
            timerStarted: false,
            time: 15000,
            isPolicyChecked: true,
            lastPhone: ''
        }
    },
    computed: {
        millesecondsToTime() {
            let h,m,s
            h = Math.floor(this.time/1000/60/60)
            m = Math.floor((this.time/1000/60/60 - h)*60)
            s = Math.floor(((this.time/1000/60/60 - h)*60 - m)*60)
            s < 10 ? s = `0${s}`: s = `${s}`
            m < 10 ? m = `0${m}`: m = `${m}`
            h < 10 ? h = `0${h}`: h = `${h}`
            return `${h}:${m}:${s}`
        },
        disabled() {
            if(this.phone.length > 15 && this.isPolicyChecked) {
                return false
            } else {
                return true
            }
        },
        callTime() {
            const currentDate = new Date()
            let selectedDate = setSeconds(setMinutes(setHours(new Date(), Number(this.callHours)), Number(this.callMinutes)), 0)

            if (isBefore(selectedDate, currentDate)) {
                selectedDate = addDays(selectedDate, 1)
            }

            return format(selectedDate, 'yyyy-MM-dd HH:mm:ss z')
        },
    },
    methods: {
        async sumbitForm() {
            if (this.timerStarted) return void 0
            this.removeErrors()
            this.startTimer()
            this.createIframe()
            this.saveAdminCallback()

            let dataForMindbox = {
                formaContact: "Обратный звонок",
                phone: this.phone,
                forOrder: false
            }

            const mindboxData = this.getDataMindboxForCartUser(dataForMindbox)

            if (!mindboxData) return void 0

            await mindboxPushCustomerForm(mindboxData)
            yandexDatalayerForm('call')

            const windowCustom: WindowCustom = window

            if (windowCustom.ym && typeof windowCustom.ym === "function") {
                const parameters = {
                    phone_number: this.phone,
                }

                windowCustom.ym(this.getYandexCounterId, 'firstPartyParams', parameters)
            }
        },
        onMaska(event: CustomEvent) {
            let el = event.target as HTMLInputElement
            if (!el) return void 0
            let phone = String(el?.dataset.maskRawValue)
            if (phone == '' || this.lastPhone == phone) return void 0

            switch (phone.substring(0, 1))
            {
            case '7':
            case '8':
                phone = phone.substring(1)
                el.dataset.maskRawValue = phone
                el.value = phone
                this.lastPhone = phone
                el.selectionStart = el.value.length
                break
            }

            if (phone.length > 10) {
                el.dataset.maskRawValue = phone.substring(0, 10)
                el.value = phone.substring(0, 10)
                el.selectionStart = el.value.length
            }

        },
        startTimer() {
            this.timerStarted = true

            const interval = setInterval(() => {
                this.time = this.time - 1000
                if (this.time <= 0) {
                    clearInterval(interval)
                    this.time = 15000
                    this.timerStarted = false
                }
            }, 1000)
        },
        removeErrors() {
            this.errors = []
        },
        createIframe() {
            if (!this.selectedTime) {
                return void 0
            }

            const domainString = document.domain

            let regex = /home24/gi
            let match = regex.exec(domainString)

            let site = match ? 3 : 1

            if (this.selectedTime == 'exact') {
                const body = new FormData()
                body.set('callTime', this.callTime)
                body.set('phone', this.phone.replace(/[^0-9]/g, ''))
                body.set('site', String(site))

                fetchRequest('/queue/createUisCallback/', {
                    method: 'POST',
                    body
                })
            } else {
                fetchRequest(`/area30/callbackUis.php?phone=${this.phone.replace(/[^0-9]/g, '')}&site=${site}`, {})
            }
        },
        saveAdminCallback() {
            const formData = new FormData()

            formData.append('phone', this.phone)
            formData.append('name', this.phone)
            formData.append('message', 'Заказ обратного звонка')
            formData.append('type', 'Остались вопросы?')
            formData.append('action', '/module/eo_question/Ajax')
            formData.append('currentUrl', location.protocol + '//' + location.host + location.pathname)

            if (this.selectedTime == 'exact' && this.callTime) {
                formData.append('callTime', this.callTime)
            }

            this.validateByCaptcha(formData)
        },
        async validateByCaptcha(formData: FormData) {
            grecaptcha.ready(
                () => {
                    grecaptcha
                        .execute(
                            '6LfRlIkpAAAAAH3bqlbbW1FrE89Xtb0ho5TZNEPI',
                            {action: 'submit'}
                        )
                        .then(async (token: string) => {
                            const captchaAction = '/module/eo_protection/CheckToken'
                            const captchaFormData = new FormData()

                            captchaFormData.append('token', token)

                            try {
                                const response = await fetch(captchaAction, {
                                    method: 'POST',
                                    body: captchaFormData,
                                    headers: commonXHRHeaders,
                                })

                                const result = await response.json()

                                if (result.success == true) {
                                    this.sendForm(formData)
                                } else {
                                    this.errors.push('Проверка действий пользователя не пройдена, возможно это ошибка. Попробуйте обновить страницу или воспользуйтесь другим браузером.')
                                }
                            } catch (error) {
                                console.error('Ошибка: ', error)
                            }
                        })
                }
            )
        },
        sendForm(formData: FormData) {
            fetchRequest('/module/eo_question/Ajax', {
                method: 'POST',
                body: formData
            })
        }
    },
})
</script>

<style lang="scss" scoped>
.radio-input {
    @apply w-4 h-4 border-[2px] border-pale-sky-100 rounded-full appearance-none cursor-pointer mr-2;

    &:checked {
        @apply border-[6px] border-black;
    }
}

.radio-label {
    @apply text-shuttle-gray text-body min-w-max cursor-pointer;
}

.select-input {
    @apply w-[82px] h-[40px] px-4 py-2 border-[2px] border-pale-sky-30 text-shuttle-gray appearance-none p-2 rounded-lg cursor-pointer;
    font-size: 16px;
    line-height: 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='4' viewBox='0 0 8 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.58579 1L2.41422 1L4.00001 2.58579L5.58579 1Z' stroke='%235E696E' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: calc(100% - 16px) 16px;

    &:focus,
    &:focus-visible,
    &:focus-within {
        @apply outline-none;
    }

    & option {
        @apply text-black disabled:text-pale-sky-100;
    }
}
</style>
