<script setup lang="ts">
import {ref, watch} from 'vue';
import {CatalogMenuCategory, CatalogMenuLink, CatalogMenuTab} from "~vue/catalog-menu/types";
import TransitionExpand from "~vue/transitions/TransitionExpand.vue";
import {saveSelectedMenuCategory, saveSelectedMenuTab} from "~vue/catalog-menu/lib";

const props = withDefaults(
    defineProps<{
        tabs: CatalogMenuTab[]
        standaloneLinks?: CatalogMenuLink[]
        modelValue: CatalogMenuTab,
        category: CatalogMenuCategory,
        template?: 'tabs' | 'accordion'
    }>(),
    {
        template: 'tabs'
    }
)

const emit = defineEmits(['update:modelValue', 'update:category']);

const activeTab = ref(props.modelValue);
const activeCategory = ref(props.category);

watch(() => props.modelValue, (newValue) => {
    activeTab.value = newValue;
});

watch(() => props.category, (newCategory) => {
    activeCategory.value = newCategory;
});

const setActiveTab = (tab: CatalogMenuTab) => {
    saveSelectedMenuTab(tab.id)
    activeTab.value = tab;
    emit('update:modelValue', tab);
};

const categoryClick = (e: Event, item: CatalogMenuCategory) => {
    if (item.groups?.length) {
        e.preventDefault();
        saveSelectedMenuCategory(item.id)

        if (window.innerWidth < 640 && activeCategory.value.id === item.id) {
            document.dispatchEvent(new CustomEvent('showMenuGroups'))
        }

        activeCategory.value = item;
        emit('update:category', item);
    }
}
</script>

<template>
    <div :class="{
            'menu-tabs': true,
            [`menu-tabs--${template}`]: true,
        }"
    >
        <template v-if="template === 'tabs'">
            <div
                class="menu-tabs__buttons"
                role="tablist"
            >
                <button
                    v-for="tab in tabs"
                    :key="tab.id"
                    role="tab"
                    :aria-controls="`tabpanel-${tab.id}`"
                    :aria-selected="tab.id === activeTab.id"
                    :class="{
                    'menu-tabs__button': true,
                    'menu-tabs__button--selected': tab.id === activeTab.id,
                }"
                    :tabindex="tabs.length === 1 ? '-1' : undefined"
                    @click="setActiveTab(tab)"
                    type="button"
                >
                    {{ tab.title }}
                </button>
            </div>

            <TransitionExpand tag="div" class="menu-tabs__contents">
                <div
                    v-for="tab in tabs"
                    v-show="tab.id === activeTab.id"
                    :id="`tabpanel-${tab.id}`"
                    :key="tab.id"
                    role="tabpanel"
                    class="menu-tabs__content"
                >
                    <ul
                        class="menu-tabs__list"
                        :aria-label="`Подразделы раздела каталога ${tab.title}`"
                    >
                        <li
                            v-for="category in tab.categories"
                            :key="category.id"
                            class="menu-tabs__list-item"
                        >
                            <img
                                v-if="category.icon"
                                style="background: url(/assets/images/dummy-white-box.svg) center no-repeat;"
                                loading="lazy"
                                class="menu-tabs__img"
                                :src="`/upload/menu-icons/${category.icon}.svg`"
                                :alt="`Иконка ${category.title}`"
                                width="40"
                                height="40"
                            >
                            <a
                                :href="category.url"
                                :class="{
                                'menu-tabs__link': true,
                                'menu-tabs__link--highlight': category.id === activeCategory.id,
                            }"
                                @click="(e) => categoryClick(e, category)"
                            >
                                {{ category.title }}
                            </a>
                        </li>
                    </ul>
                </div>
            </TransitionExpand>
        </template>

        <template v-else>
            <div
                v-for="tab in tabs"
                :key="tab.id"
                class="menu-tabs__item"
            >
                <button
                    :aria-controls="`menu-tabs-content-${tab.id}`"
                    :aria-expanded="tab.id === activeTab.id"
                    :class="{
                    'menu-tabs__button': true,
                    'menu-tabs__button--selected': tab.id === activeTab.id,
                }"
                    @click="setActiveTab(tab)"
                    type="button"
                >
                    {{ tab.title }}
                </button>

                <TransitionExpand>
                    <ul
                        v-show="tab.id === activeTab.id"
                        :id="`menu-tabs-content-${tab.id}`"
                        :key="tab.id"
                        :aria-hidden="tab.id !== activeTab.id"
                        class="menu-tabs__list"
                        :aria-label="`Подразделы раздела каталога ${tab.title}`"
                    >
                        <li
                            v-for="category in tab.categories"
                            :key="category.id"
                            class="menu-tabs__list-item"
                        >
                            <img
                                v-if="category.icon"
                                style="background: url(/assets/images/dummy-white-box.svg) center no-repeat;"
                                loading="lazy"
                                class="menu-tabs__img"
                                :src="`/upload/menu-icons/${category.icon}.svg`"
                                :alt="`Иконка ${category.title}`"
                            >
                            <a
                                :href="category.url"
                                :class="{
                                'menu-tabs__link': true,
                                'menu-tabs__link--highlight': category.id === activeCategory.id,
                            }"
                                @click="(e) => categoryClick(e, category)"
                            >
                                {{ category.title }}
                            </a>
                        </li>
                    </ul>
                </TransitionExpand>
            </div>
        </template>

        <ul
            v-if="standaloneLinks?.length"
            class="menu-tabs__list menu-tabs__list--standalone"
            aria-label="Основные разделы каталога"
        >
            <li
                v-for="link in standaloneLinks"
                :key="link.url"
                class="menu-tabs__list-item"
            >
                <a
                    :href="link.url"
                    :class="{
                        'menu-tabs__link': true,
                        'menu-tabs__link--standalone': true,
                        'menu-tabs__link--highlight': link.highlight,
                    }"
                >
                    {{ link.title }}
                </a>
            </li>
        </ul>
    </div>
</template>

<style scoped lang="scss">
$className: "menu-tabs";

.#{$className} {
    @apply flex flex-col;

    &--accordion {
        @apply gap-4;

        & .#{$className}__button {
            transition-property: color, font-size;

            @apply border-none;

            &--selected {
                @apply border-none text-black text-menu-item;
            }
        }

        & .#{$className}__list {
            @apply mt-2;

            &--standalone {
                @apply z-[4] mt-0;
            }
        }
    }

    &__buttons {
        @apply flex mb-6 z-[4] gap-6;
    }

    &__button {
        transition-property: color, border-color;

        @apply uppercase
            text-pale-sky-70
            border-b-[2px] border-b-transparent
            duration-300
            ease-ease;

        &--selected {
            @apply border-b-black text-black;
        }
    }

    &__contents {
        @apply z-[4];

        &:not(&:last-child) {
            @apply mb-4;
        }
    }

    &__item {
        @apply z-[4];
    }

    &__list {
        @apply flex flex-col gap-2;

        &--standalone {
            @apply z-[4];
        }
    }

    &__list-item {
        @apply
            flex
            gap-2
            text-pale-sky-70
            items-center
            relative
        ;
    }

    &__link {
        @apply text-pale-sky-70;

        &::after {
            content: '';

            @apply
                absolute
                inset-0
            ;
        }

        &:not(&--standalone) {
            &:hover {
                @apply text-black;
            }
        }

        &--standalone {
            @apply uppercase;
        }

        &--highlight {
            @apply text-orange;
        }
    }

    &__img {
        background: url(/assets/images/dummy-white-box.svg) center no-repeat;

        @apply
            text-[0]
            w-10
            h-10
            flex-shrink-0
        ;
    }
}
</style>
